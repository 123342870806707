import React, {useEffect, useRef, useState} from "react";
import {isNullOrUndef} from "chart.js/helpers";
import moment from "moment";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {StatusService} from "../../../service/StatusService";
import {useMountEffect} from "primereact/hooks";
import {TradeTypesService} from "../../../service/TradeTypesService";
import {TransportModeService} from "../../../service/TransportModeService";
import {IncotermsService} from "../../../service/IncotermsService";
import {CountryDirectionService} from "../../../service/CountryDirectionService";
import {ShippingLinesService} from "../../../service/ShippingLinesService";
import {ContainerTypesService} from "../../../service/ContainerTypesService";
import {PlacesService} from "../../../service/PlacesService";
import {PortService} from "../../../service/PortService";
import {ClientService} from "../../../service/ClientService";
import {AutoComplete} from "primereact/autocomplete";
import {Checkbox} from "primereact/checkbox";
import {CSSTransition} from 'react-transition-group'
import {Calendar} from "primereact/calendar";
import {BookingsService} from "../../../service/BookingsService";
import {UserService} from "../../../service/UserService";
import {MultiSelect} from "primereact/multiselect";
import {calendarFooterTemplate, todayCalendar} from "../../../App";

export const BookingFilters = (props) => {

    const [filterList, setFilterList] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [tradeTypes, setTradeTypes] = useState([]);
    const [transportModes, setTransportModes] = useState([]);
    const [incoterms, setIncoterms] = useState([]);
    const [directions, setDirections] = useState([]);
    const [shippingLines, setShippingLines] = useState([]);
    const [containerTypes, setContainerTypes] = useState([]);
    const [billOfLadingStatuses, setBillOfLadingStatuses] = useState([]);
    const [importerSecurityFillings, setImporterSecurityFillings] = useState([]);
    const [filteredClients, setFilteredClients] = useState(null);
    const [filteredPorts, setFilteredPorts] = useState(null);
    const [filteredPlaces, setFilteredPlaces] = useState(null);
    const [managers, setManagers] = useState([]);
    const [operationalStatuses, setOperationalStatuses] = useState([]);

    const statusService = new StatusService();
    const tradeTypeService = new TradeTypesService();
    const transportModeService = new TransportModeService();
    const incotermsService = new IncotermsService();
    const countryDirectionService = new CountryDirectionService();
    const shippingLineService = new ShippingLinesService();
    const containerTypesService = new ContainerTypesService();
    const clientService = new ClientService();
    const placesService = new PlacesService();
    const portService = new PortService();
    const bookingsService = new BookingsService();
    const userService = new UserService();

    const filterDateType = ['portOfLoadingDeparture', 'portOfDischargeArrival', 'portOfDischargeArrivalNotice', 'cutOffDate', 'cargos.placeOfDischargeArrival', 'cargos.dateOfLoading', 'cargos.lastFreeDay', 'bookingDate']

    let calendarRef = useRef(null);
    let calendarInputRef = useRef(null);

    useMountEffect(() => {
        statusService.getStatuses().then(data => {
            setStatusOptions(data)
        })
        tradeTypeService.getTradeTypes().then(data => {
            data.unshift({id: -1, name: '-----'})
            setTradeTypes(data)
        })
        transportModeService.getTransportModes().then(data => {
            data.unshift({id: -1, name: '-----'})
            setTransportModes(data)
        })
        incotermsService.getIncoterms().then(data => {
            data.unshift({id: -1, name: '-----'})
            setIncoterms(data)
        })
        countryDirectionService.getCountryDirections().then(data => {
            data.unshift({id: -1, name: '-----'})
            setDirections(data)
            if(props.typeLoad === 'wages'){
                props.setDirectionsList(data);
            }
        })
        shippingLineService.getShippingLines().then(data => {
            data.unshift({id: -1, name: '-----'})
            setShippingLines(data)
        })
        containerTypesService.getContainerTypes().then(data => {
            data.unshift({id: -1, name: '-----'})
            setContainerTypes(data)
        })
        bookingsService.getBillOfLadingStatuses().then(data => {
            data.unshift({id: -1, name: '-----'})
            setBillOfLadingStatuses(data)
        });
        bookingsService.getImporterSecurityFilling().then(data => {
            data.unshift({id: -1, name: '-----'})
            setImporterSecurityFillings(data)
        });


        userService.getManagers().then(data => {
            data.forEach(m => m['name'] = m.firstName + ' ' + m.lastName)
            data.unshift({id: -1, name: '-----'})
            setManagers(data);
        });

        setOperationalStatuses([
            {id: 'WORKING', name: 'Working'},
            {id: 'TRANSFERRING', name: 'Transferring'},
            {id: 'CLOSED', name: 'Closed'},
        ]);

        let filterListStorage = JSON.parse(window.localStorage.getItem('bookings-datatable-filters'));
        if(props.typeLoad === 'planReal'){
            filterListStorage = JSON.parse(window.localStorage.getItem('plan-real-datatable-filters'));
        } else if(props.typeLoad === 'operations'){
            filterListStorage = JSON.parse(window.localStorage.getItem('bookings-operations-datatable-filters'));
        }
        if(!isNullOrUndef(filterListStorage)){
            for (const key in filterListStorage) {
                if(filterDateType.includes(key) && !isNullOrUndef(filterListStorage[key])){
                    let dateFrom = !isNullOrUndef(filterListStorage[key][0])
                        ? moment(filterListStorage[key][0]).toDate() : null
                    let dateTo = !isNullOrUndef(filterListStorage[key][1])
                        ? moment(filterListStorage[key][1]).toDate() : null
                    filterListStorage[key] = [dateFrom, dateTo]
                }
            }
            setFilterList(filterListStorage)
        }
    })

    useEffect(() => {
        if(props.searchBooking > 0){
            searchBooking()
        }
    }, [props.searchBooking])

    useEffect(() => {
        if(props.clearFilter > 0){
            if(props.typeLoad === 'planReal'){
                localStorage.removeItem('plan-real-filter-data');
                localStorage.removeItem('plan-real-datatable-filters');
            }
            if(props.typeLoad === 'bookings'){
                localStorage.removeItem('bookings-filter-data');
                localStorage.removeItem('bookings-datatable-filters');
            }
            if(props.typeLoad === 'operations'){
                localStorage.removeItem('bookings-operations-report-filter-data');
                localStorage.removeItem('bookings-operations-report-datatable-filters');
            }
            if(props.typeLoad === 'wages'){
                localStorage.removeItem('wages-filter-data');
                localStorage.removeItem('wages-real-datatable-filters');
            }
            clearFilter()
        }
    }, [props.clearFilter])

    const searchBooking = () => {
        let filterParam = ''
        let action = 0;

        if(props.typeLoad === 'planReal'){
            window.localStorage.setItem('plan-real-datatable-filters', JSON.stringify(filterList))
        }
        if(props.typeLoad === 'bookings'){
            window.localStorage.setItem('bookings-datatable-filters', JSON.stringify(filterList))
        }
        if(props.typeLoad === 'operations'){
            window.localStorage.setItem('bookings-operations-report-datatable-filters', JSON.stringify(filterList))
        }
        if(props.typeLoad === 'wages'){
            window.localStorage.setItem('wages-real-datatable-filters', JSON.stringify(filterList))
        }
        Object.keys(filterList).map(key => {
            if (!isNullOrUndef(filterList[key]) && filterList[key] !== '') {
                action = action + 1
                switch (key) {
                    case 'booking.id':
                        return filterParam += ',booking.id:' + filterList[key]
                    case 'refId':
                        return filterParam += ',id:' + filterList[key]
                    case 'number':
                        return filterParam += ',number:' + filterList[key]
                    case 'user':
                        return filterParam += ',user.firstName:' + filterList[key]
                    case 'cargos.containerNumber':
                        return filterParam += ',cargos.containerNumber:' + filterList[key]
                    case 'customerRefId':
                        return filterParam += ',customerRefId:' + filterList[key]
                    case 'status':
                        return filterParam += ',status.id~' + filterList[key].map(s => s).join(';')
                    case 'tradeType':
                        return filterParam += ',tradeType.id:' + filterList[key]
                    case 'transportMode':
                        return filterParam += ',transportMode.id:' + filterList[key]
                    case 'portOfLoading':
                        return filterParam += ',portOfLoading.id:' + filterList[key].id
                    case 'portOfDischarge':
                        return filterParam += ',portOfDischarge.id:' + filterList[key].id
                    case 'placeOfLoading':
                        return filterParam += ',placeOfLoading.id:' + filterList[key].id
                    case 'placeOfDischarge':
                        return filterParam += ',placeOfDischarge.id:' + filterList[key].id
                    case 'incotermId':
                        return filterParam += ',booking.incoterm.id:' + filterList[key]
                    case 'incoterm':
                        return filterParam += ',incoterm.id:' + filterList[key]
                    case 'customer':
                        return filterParam += ',client.id:' + filterList[key].id
                    case 'shipper':
                        return filterParam += ',shipper.id:' + filterList[key].id
                    case 'consignee':
                        return filterParam += ',consignee.id:' + filterList[key].id
                    case 'shippingLine':
                        return filterParam += ',shippingLine.id:' + filterList[key]
                    case 'direction':
                        return filterParam += ',direction.id:' + filterList[key]
                    case 'forwarder':
                        return filterParam += ',forwarder.id:' + filterList[key].id
                    case 'containerType':
                        return filterParam += ',cargos.containerType.id:' + filterList[key]
                    case 'customsClearance':
                        return filterParam += ',customsClearance:' + filterList[key]
                    case 'verifiedGrossMass':
                        return filterParam += ',verifiedGrossMass:' + filterList[key]
                    case 'proofOfDelivery':
                        return filterParam += ',proofOfDelivery:' + filterList[key]
                    case 'shippingInstruction':
                        return filterParam += ',shippingInstruction:' + filterList[key]
                    case 'billOfLadingStatus':
                        return filterParam += ',billOfLadingStatus:' + filterList[key]
                    case 'importerSecurityFilling':
                        return filterParam += ',importerSecurityFilling:' + filterList[key]
                    case 'kipis':
                        return filterParam += ',kipis:' + filterList[key]
                    case 'insurance':
                        return filterParam += ',insurance:' + filterList[key]
                    case 'portOfLoadingDeparture':
                    case 'portOfDischargeArrival':
                    case 'portOfDischargeArrivalNotice':
                    case 'cutOffDate':
                    case 'cargos.dateOfLoading':
                    case 'cargos.placeOfDischargeArrival':
                    case 'cargos.lastFreeDay':
                    case 'bookingDate':
                    case 'operationStartedAt':
                    case 'operationEndedAt':
                        return filterParam += makeCalendarParams(filterList[key], key)
                    case 'manager':
                        return filterParam += ',manager.id:' + filterList[key]
                    case 'operator':
                        return filterParam += ',operator.id:' + filterList[key]
                    case 'nextOperator':
                        return filterParam += ',nextOperator.id:' + filterList[key]
                    case 'startBookingStatus':
                        return filterParam += ',startBookingStatus.id~' + filterList[key].map(s => s).join(';')
                    case 'endBookingStatus':
                        return filterParam += ',endBookingStatus.id~' + filterList[key].map(s => s).join(';')
                    case 'operationalStatus':
                        return filterParam += ',operationalStatus~' + filterList[key].map(s => s).join(';')
                    case 'booking.operationalStatus':
                        return filterParam += ',booking.operationalStatus~' + filterList[key].map(s => s).join(';')
                    case 'pendingInvoice':
                        return filterParam += ',invoices.invoiceType:DEBIT,invoices.invoiceStatus:PENDING'
                }
            } else if(filterList.hasOwnProperty(key)){
                delete filterList[key]
            }
        });
        if(action === 0){
            filterParam = null
        }
        if(props.typeLoad === 'planReal'){
            localStorage.removeItem('plan-real-filter-data');
        }
        if(props.typeLoad === 'bookings'){
            localStorage.removeItem('bookings-filter-data');
        }
        if(props.typeLoad === 'operations'){
            localStorage.removeItem('bookings-operations-report-filter-data');
        }

        props.setFilters(filterParam)
    }

    const makeCalendarParams = (dates, key) => {
        if (!isNullOrUndef(dates[0]) && !isNullOrUndef(dates[1])) {
            return `,${key}>${moment(dates[0]).format('YYYY-MM-DD')},${key}<${moment(dates[1]).format('YYYY-MM-DD')}`
        } else if (!isNullOrUndef(dates[0])) {
            return `,${key}:${moment(dates[0]).format('YYYY-MM-DD')}`
        } else {
            return ''
        }

    }

    const loadFilters = () => {
        let filters = props.selectedColumns.map(c => {
            switch (c.key) {
                case 'user':
                    return inputFilter(c);
                case 'number':
                    return inputFilter(c);
                case 'cargos.containerNumber':
                    return inputFilter(c);
                case 'customerRefId':
                    return inputFilter(c);
                case 'containerType':
                    return dropDownFieldFilter(c, containerTypes, 'value');
                case 'status':
                case 'startBookingStatus':
                case 'endBookingStatus':
                    return multiSelectFieldFilter(c, statusOptions, 'name');
                case 'tradeType':
                    return dropDownFieldFilter(c, tradeTypes, 'name');
                case 'transportMode':
                    return dropDownFieldFilter(c, transportModes, 'name');
                case 'portOfLoading':
                    return autocompleteFieldFilter(c, filteredPorts, searchPort, portItemTemplate, portItemTemplate);
                case 'portOfDischarge':
                    return autocompleteFieldFilter(c, filteredPorts, searchPort, portItemTemplate, portItemTemplate);
                case 'placeOfLoading':
                    return autocompleteFieldFilter(c, filteredPlaces, searchPlace, null, 'name');
                case 'placeOfDischarge':
                    return autocompleteFieldFilter(c, filteredPlaces, searchPlace, null, 'name');
                case 'incoterm':
                case 'incotermId':
                    return dropDownFieldFilter(c, incoterms, 'title');
                case 'customer':
                    return autocompleteFieldFilterClient(c, filteredClients, searchClient, null, 'title', props.getClientGroupId('customer'));
                case 'manager':
                    if(props.typeLoad === 'wages' && props.isAdmim === false) {
                        return;
                    } else {
                        return dropDownFieldFilter(c, managers, 'name');
                    }
                case 'operator':
                case 'nextOperator':
                    return dropDownFieldFilter(c, managers, 'name');
                case 'booking.operationalStatus':
                    return multiSelectFieldFilter(c, operationalStatuses, 'name');
                case 'operationalStatus':
                    return multiSelectFieldFilter(c, operationalStatuses, 'name');
                case 'shipper':
                    return autocompleteFieldFilterClient(c, filteredClients, searchClient, null, 'title', props.getClientGroupId('shipper'));
                case 'consignee':
                    return autocompleteFieldFilterClient(c, filteredClients, searchClient, null, 'title', props.getClientGroupId('consignee'));
                case 'shippingLine':
                    return dropDownFieldFilter(c, shippingLines, 'title');
                case 'direction':
                    return dropDownFieldFilter(c, directions, 'title');
                case 'forwarder':
                    return autocompleteFieldFilterClient(c, filteredClients, searchClient, null, 'title', props.getClientGroupId('forwarder'));
                case 'billOfLadingStatus':
                    return dropDownFieldFilter(c, billOfLadingStatuses, 'title');
                case 'importerSecurityFilling':
                    return dropDownFieldFilter(c, importerSecurityFillings, 'title');
                case 'portOfLoadingDeparture':
                case 'portOfDischargeArrival':
                case 'portOfDischargeArrivalNotice':
                case 'cutOffDate':
                case 'cargos.lastFreeDay':
                case 'cargos.dateOfLoading':
                case 'cargos.placeOfDischargeArrival':
                case 'operationStartedAt':
                case 'operationEndedAt':
                    return calendarFieldFilter(c);
                case 'customsClearance':
                case 'verifiedGrossMass':
                case 'shippingInstruction':
                case 'kipis':
                case 'insurance':
                case 'proofOfDelivery':
                    return booleanFieldFilter(c);
                case 'bookingDate':
                    return calendarFieldFilter(c);
                case 'pendingInvoice':
                    return booleanFieldFilter(c);
                default:
                    return ''
            }
        })

        if(props.typeLoad === 'operations') {
            filters.unshift(inputFilter({key: 'booking.id', header: 'Ref.ID', field: 'booking.id'}))
        } else {
            filters.unshift(inputFilter({key: 'refId', header: 'Ref.ID', field: 'refId'}))
        }
        return <div className={'p-fluid p-grid p-col-12 p-p-0'}>
            {filters}
        </div>
    }

    const searchPort = (event) => {
        if (event.query.trim().length > 2) {
            portService.searchPorts(event.query.trim().toLowerCase()).then(data => {
                setFilteredPorts(data)
            });
        }
    }

    const searchClient = (query, clientGroupId) => {
        if (query.trim().length > 2) {
            clientService.searchClientsByGroupId(query.trim().toLowerCase(), clientGroupId).then(data => {
                setFilteredClients(data)
            });
        }
    }

    const searchPlace = (event) => {
        if (event.query.trim().length > 2) {
            placesService.searchPlaces(event.query.trim().toLowerCase()).then(data => {
                setFilteredPlaces(data);
            });
        }

    }

    const clearFilter = () => {
        setFilterList([])
        if(props.filters !== '') {
            props.setFilters('')
        } else {
            props.getBookings()
        }
    }

    const inputFilter = (column) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <InputText id={`filter-${column.key}`}
                       value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                       className={'block'}
                       onChange={(e) => setFilterList({...filterList, [column.key]: e.target.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    const portItemTemplate = (item) => {
        return item.name + ', ' + item.country.title
    }

    const onChangeDropDownValue = (e, columnKey) => {
        if (!isNullOrUndef(e.value) && e.value > 0) {
            setFilterList({...filterList, [columnKey]: e.value})
        } else {
            setFilterList({...filterList, [columnKey]: null})
        }
    }
    const onChangeMultiSelectValue = (e, columnKey) => {
        if (!isNullOrUndef(e.value) && e.value.length > 0) {
            setFilterList({...filterList, [columnKey]: e.value})
        } else {
            setFilterList({...filterList, [columnKey]: null})
        }
    }

    const onChangeBooleanValue = (value, columnKey) => {
        if (!isNullOrUndef(value) && value) {
            setFilterList({...filterList, [columnKey]: value})
        } else {
            setFilterList({...filterList, [columnKey]: null})
        }
    }

    const multiSelectFieldFilter = (column, options, field) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
        <MultiSelect id={`filter-${column.key}`} maxSelectedLabels={1}
                     value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                     options={options} optionLabel={field} optionValue={'id'} dropdownIcon={null}
                     onChange={(e) => onChangeMultiSelectValue(e, column.key)}/>
                <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    const dropDownFieldFilter = (column, options, field) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <Dropdown id={`filter-${column.key}`} options={options}
                      value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                      onChange={(e) => onChangeDropDownValue(e, column.key)}
                      optionLabel={field} optionValue={'id'} dropdownIcon={null}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }
    const autocompleteFieldFilter = (column, options, searchObj, template, field) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <AutoComplete id={`filter-${column.key}`}
                          value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                          suggestions={options} completeMethod={searchObj} minLength={3} showEmptyMessage
                          tooltip={'Min 3 characters'} itemTemplate={template} field={field}
                          onChange={(e) => setFilterList({...filterList, [column.key]: e.target.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    const autocompleteFieldFilterClient = (column, options, searchObj, template, field, clientGroupId) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <AutoComplete id={`filter-${column.key}`}
                          value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                          suggestions={options} completeMethod={e => searchObj(e.query, clientGroupId)} minLength={3} showEmptyMessage
                          tooltip={'Min 3 characters'} itemTemplate={template} field={field}
                          onChange={(e) => setFilterList({...filterList, [column.key]: e.target.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    const booleanFieldFilter = (column, options) => {
        let className = column.key === 'pendingInvoice' ? 'p-field p-col-12 p-md-2 p-mb-0' : 'p-field p-col-12 p-md-1 p-mb-0'
        return <div key={column.key} className={className}>
            <Checkbox inputId={`filter-${column.key}`}
                      value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : false}
                      checked={filterList[column.key]} style={{marginBottom: '7px'}}
                      onChange={(e) => onChangeBooleanValue(e.checked, column.key)}/>
            <label style={{marginLeft: '5px', marginTop: '10px'}}
                   htmlFor={`filter-${column.key}`}>{column.header}</label>
        </div>
    }

    const calendarFieldFilter = (column, options) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <Calendar id={`filter-${column.key}`} selectionMode="range" inputRef={calendarInputRef}
                      viewDate={todayCalendar()} numberOfMonths={3} ref={calendarRef}
                      footerTemplate={() => calendarFooterTemplate(calendarRef, calendarInputRef)}
                      dateFormat={'yy-mm-dd'} locale={'lt'}
                      value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : null}
                      onChange={(e) => setFilterList({...filterList, [column.key]: e.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    return <CSSTransition in={props.filter} timeout={{enter: 400, exit: 400}}
                          classNames="filters" unmountOnExit appear>
        <div id={'booking-filter'} className={'mt-2'}>
            {props.selectedColumns.length > 0 ? loadFilters() : ''}
        </div>
    </CSSTransition>
}