import React, {useEffect, useRef, useState} from 'react';
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {AutoComplete} from "primereact/autocomplete";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import {isNullOrUndef} from "chart.js/helpers";
import {ClientService} from "../../../service/ClientService";
import {PlacesService} from "../../../service/PlacesService";
import {PortService} from "../../../service/PortService";
import {Accordion, AccordionTab} from "primereact/accordion";
import {CargosModule} from "../modules/CargosModule";
import {CargoItemsModule} from "../modules/CargoItemsModule";
import classNames from "classnames";
import {Badge} from "primereact/badge";
import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {onViewChange, todayCalendar} from "../../../App";

export const InfoTab = (props) => {

    const [filteredClients, setFilteredClients] = useState(null);
    const [filteredPorts, setFilteredPorts] = useState(null);
    const [filteredPlaces, setFilteredPlaces] = useState(null);
    const [notFoundPlace, setNotFoundPlace] = useState(null);
    const [notFoundClient, setNotFoundClient] = useState(null);
    const [podUpdateRegisterDialog, setPodUpdateRegisterDialog] = useState(false);
    const clientService = new ClientService();
    const placesService = new PlacesService();
    const portService = new PortService();
    let calendarRef = useRef(null);
    const directionItemTemplate = (option, props) => {
        if (option) {
            return option.title
        }
        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const searchPort = (event) => {
        if (event.query.trim().length > 2) {
            portService.searchPorts(event.query.trim().toLowerCase()).then(data => {
                setFilteredPorts(data)
            });
        }
    }

    const searchClient = (event, clientGroupId) => {
        if (event.query.trim().length > 2) {
            clientService.searchClientsByGroupId(event.query.trim().toLowerCase(), clientGroupId).then(data => {
                setFilteredClients(data)
                if (data.length === 0) {
                    setNotFoundClient(event.query)
                }
            });
        }
    }

    const searchPlace = (event) => {
        if (event.query.trim().length > 2) {
            placesService.searchPlaces(event.query.trim().toLowerCase()).then(data => {
                setFilteredPlaces(data);
                if (data.length === 0) {
                    setNotFoundPlace(event.query)
                }
            });
        }

    }

    const addNewPlace = (obj) => {
        let placeDTO = {
            id: null,
            name: notFoundPlace,
            placeTypeId: null
        }
        placesService.addNewPlace(placeDTO).then(data => {
            props.onChangeValue(data, obj)
            let _filteredPlaces = [];
            _filteredPlaces.push(data)
            setFilteredPlaces(_filteredPlaces)
        })
    }

    const addNewClient = (obj) => {
        let clientDTO = {
            id: null,
            title: notFoundClient,
            clientGroupIds: [props.clientGroups.find(d => d.title.toLowerCase().trim().includes(obj.toLowerCase().trim())).id],
        }
        clientService.saveClient(clientDTO).then(data => {
            props.onChangeValue(data, obj)
            let _filteredClient = [];
            _filteredClient.push(data)
            setFilteredClients(_filteredClient)
        })
    }

    const emptyMessage = (type, obj) => {
        let label = 'Add this'
        let button = <Button type="button" label={label} icon="pi pi-plus"
                             className="p-button-info p-button-sm" onClick={() => addNewPlace(obj)}/>
        if (type === 'client') {
            button = <Button type="button" label={label} icon="pi pi-plus"
                             className="p-button-info p-button-sm" onClick={() => addNewClient(obj)}/>
        }
        return <div style={{textAlign: 'center'}}>
            <div>No results found.</div>
            {button}
        </div>
    }

    const portItemTemplate = (item) => {
        return item.name + ', ' + item.country.title
    }

    const onAccordionOpen = (e) => {
        let accordions = JSON.parse(window.localStorage.getItem('bookings-accordion'));
        if (accordions !== null) {
            accordions = accordions.concat(e.index)
            window.localStorage.setItem('bookings-accordion', JSON.stringify(accordions))
        }
    }

    const onAccordionClose = (e) => {
        let accordions = JSON.parse(window.localStorage.getItem('bookings-accordion'));
        if (accordions !== null) {
            accordions.splice(accordions.indexOf(e.index), 1);
            window.localStorage.setItem('bookings-accordion', JSON.stringify(accordions))
        }
    }

    const getAccordionActive = () => {
        const accordions = JSON.parse(window.localStorage.getItem('bookings-accordion'));
        if (accordions !== null) {
            return accordions
        } else {
            window.localStorage.setItem('bookings-accordion', JSON.stringify([0, 1, 2, 3]))
            return [0, 1, 2, 3];
        }
    }

    const getClientGroupId = (type) => {
        if(!isNullOrUndef(props.clientGroups) && !isNullOrUndef(type)) {
            let groupId;
            props.clientGroups.forEach(cg => {
                if(cg.title.toLowerCase().trim().includes(type)){
                    groupId = cg.id
                }
            });
            return groupId;
        }
    }

    const containersCount = () => {
      return !isNullOrUndef(props.booking.cargos) && props.booking.cargos.length > 0
          ? `(${props.booking.cargos.length})` : '';
    }

    return <div className="p-fluid p-grid">
        <Accordion multiple activeIndex={getAccordionActive()} className={'p-col-12'}
                   onTabOpen={onAccordionOpen} onTabClose={onAccordionClose}>
            <AccordionTab header={<b>Main Info</b>} disabled={props.activatedEdit}
                          headerStyle={{borderBottom: '1px solid rgba(0, 0, 0, 0.6)'}}>
                <div className="p-fluid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="tradeTypes">Imp/Exp</label>
                        <Dropdown id={'tradeTypes'} value={props.booking.tradeTypeId} options={props.tradeTypes}
                                  onChange={(e) => props.onChangeValue(e, 'tradeTypeId')}
                                  disabled={props.checkDisabledField('tradeTypeId')}
                                  optionLabel="name" optionValue={'id'} placeholder="-- Select --" required
                                  className={classNames({'p-invalid': props.validMessage('tradeTypeId')})}/>
                        {props.validMessage('tradeTypeId') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="status">Status</label>
                        <Dropdown id={'status'} value={props.booking.statusId} options={props.statusOptions} required
                                  onChange={(e) => props.onChangeValue(e, 'statusId')} panelClassName={'status'}
                                  optionLabel="name" optionValue={'id'} placeholder="-- Select --"
                                  disabled={!props.checkUserRole(['ROLE_ADMIN']) ? true : props.checkDisabledField('statusId')}
                                  className={classNames({'p-invalid': props.validMessage('statusId')})}/>
                        {props.validMessage('statusId') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="bookingClient">Customer</label>
                        <AutoComplete id={'bookingClient'} value={props.booking.client} suggestions={filteredClients}
                                      completeMethod={(e) => searchClient(e, getClientGroupId('customer'))}
                                      showEmptyMessage field="title"
                                      tooltip={'Min 3 characters'} emptyMessage={'No results found.'}
                                      onChange={(e) => props.onChangeValue(e, 'client')}
                                      minLength={3} placeholder="-- Select --"
                                      disabled={props.checkDisabledField('client')}
                                      className={classNames({'p-invalid': props.validMessage('client')})}/>
                        {props.validMessage('client') && <small className="p-invalid">Required</small>}
                        {props.checkManagerError && props.bookingClientIsEmpty
                            && <small className="p-invalid">Not selected!</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="bookingNumber">Booking number</label>
                        <InputText id="bookingNumber" value={!isNullOrUndef(props.booking.number) ? props.booking.number : ''}
                                   disabled={props.checkDisabledField('number')}
                                   onChange={(e) => props.onChangeValue(e, 'number')}
                                   className={classNames({'p-invalid': props.validMessage('number')})}/>
                        {props.validMessage('number') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="transportMode">Transport mode</label>
                        <Dropdown id={'transportMode'} value={props.booking.transportModeId}
                                  options={props.transportModes}
                                  disabled={props.checkDisabledField('transportModeId')}
                                  onChange={(e) => props.onChangeValue(e, 'transportModeId')}
                                  showClear optionLabel="name" optionValue={'id'} placeholder="-- Select --"
                                  className={classNames({'p-invalid': props.validMessage('transportModeId')})}/>
                        {props.validMessage('transportModeId') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="incoterm">Incoterms</label>
                        <Dropdown id={'incoterm'} value={props.booking.incotermId} options={props.incoterms}
                                  disabled={props.checkDisabledField('incotermId')}
                                  onChange={(e) => props.onChangeValue(e, 'incotermId')}
                                  showClear filter optionLabel="title" optionValue={'id'} placeholder="-- Select --"
                                  className={classNames({'p-invalid': props.validMessage('incotermId')})}/>
                        {props.validMessage('incotermId') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="shipper">Shipper</label>
                        <AutoComplete id={'shipper'} value={props.booking.shipper} suggestions={filteredClients}
                                      completeMethod={(e) => searchClient(e, getClientGroupId('shipper'))}
                                      showEmptyMessage field="title"
                                      disabled={props.checkDisabledField('shipper')}
                                      tooltip={'Min 3 characters'} emptyMessage={emptyMessage('client', 'shipper')}
                                      onChange={(e) => props.onChangeValue(e, 'shipper')} minLength={3}
                                      placeholder="-- Select --"
                                      className={classNames({'p-invalid': props.validMessage('shipper')})}/>
                        {props.validMessage('shipper') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="consignee">Consignee</label>
                        <AutoComplete id={'consignee'} value={props.booking.consignee} suggestions={filteredClients}
                                      completeMethod={(e) => searchClient(e, getClientGroupId('consignee'))}
                                      showEmptyMessage field="title"
                                      tooltip={'Min 3 characters'} emptyMessage={emptyMessage('client', 'consignee')}
                                      onChange={(e) => props.onChangeValue(e, 'consignee')}
                                      minLength={3} placeholder="-- Select --"
                                      disabled={props.checkDisabledField('consignee')}
                                      className={classNames({'p-invalid': props.validMessage('consignee')})}/>
                        {props.validMessage('consignee') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="direction">Trades</label>
                        <Dropdown id={'direction'} value={props.booking.directionId} options={props.directions}
                                  valueTemplate={directionItemTemplate}
                                  disabled={props.checkDisabledField('directionId')}
                                  onChange={(e) => props.onChangeValue(e, 'directionId')}
                                  showClear filter optionLabel="title" optionValue={'id'} placeholder="-- Select --"
                                  className={classNames({'p-invalid': props.validMessage('directionId')})}/>
                        {props.validMessage('directionId') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="forwarder">Forwarder</label>
                        <AutoComplete id={'forwarder'} value={props.booking.forwarder} suggestions={filteredClients}
                                      completeMethod={(e) => searchClient(e, getClientGroupId('forwarder'))}
                                      showEmptyMessage field="title"
                                      tooltip={'Min 3 characters'} emptyMessage={emptyMessage('client', 'forwarder')}
                                      onChange={(e) => props.onChangeValue(e, 'forwarder')}
                                      minLength={3} placeholder="-- Select --"
                                      disabled={props.checkDisabledField('forwarder')}
                                      className={classNames({'p-invalid': props.validMessage('forwarder')})}/>
                        {props.validMessage('forwarder') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="shippingLine">Shipping line</label>
                        <Dropdown id={'shippingLine'} value={props.booking.shippingLineId} options={props.shippingLines}
                                  disabled={props.checkDisabledField('shippingLineId')}
                                  onChange={(e) => props.onChangeValue(e, 'shippingLineId')}
                                  showClear filter optionLabel="title" optionValue={'id'} placeholder="-- Select --"
                                  className={classNames({'p-invalid': props.validMessage('shippingLineId')})}/>
                        {props.validMessage('shippingLineId') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="customerRefId">Customer Ref.</label>
                        <InputText id="customerRefId" value={!isNullOrUndef(props.booking.customerRefId) ? props.booking.customerRefId : ''}
                                   disabled={props.checkDisabledField('customerRefId')}
                                   onChange={(e) => props.onChangeValue(e, 'customerRefId')}
                                   className={classNames({'p-invalid': props.validMessage('customerRefId')})}/>
                        {props.validMessage('customerRefId') && <small className="p-invalid">Required</small>}
                    </div>
                </div>
            </AccordionTab>
            <AccordionTab header={<b>Additional Info</b>} disabled={props.activatedEdit}
                          headerStyle={{borderBottom: '1px solid rgba(0, 0, 0, 0.6)'}}>
                <div className="p-fluid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="billOfLadingStatus">Bill of lading status</label>
                        <Dropdown id={'billOfLadingStatus'} value={props.booking.billOfLadingStatus} showClear
                                  disabled={props.checkDisabledField('billOfLadingStatus')}
                                  onChange={(e) => props.onChangeValue(e, 'billOfLadingStatus')}
                                  options={props.billOfLadingStatuses} optionLabel="title" placeholder="-- Select --"
                                  className={classNames({'p-invalid': props.validMessage('billOfLadingStatus')})}/>
                        {props.validMessage('billOfLadingStatus') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="importerSecurityFilling">Importer Security Filling</label>
                        <Dropdown id={'importerSecurityFilling'} value={props.booking.importerSecurityFilling} showClear
                                  options={props.importerSecurityFillings} optionLabel="title"
                                  placeholder="-- Select --" disabled={props.checkDisabledField('importerSecurityFilling')}
                                  onChange={(e) => props.onChangeValue(e, 'importerSecurityFilling')}
                                  className={classNames({'p-invalid': props.validMessage('importerSecurityFilling')})}/>
                        {props.validMessage('importerSecurityFilling') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <div className={'p-field additionalInfoCheckbox'}>
                            <Checkbox inputId="customsClearance" checked={props.booking.customsClearance}
                                      disabled={props.checkDisabledField('customsClearance')}
                                      onChange={e => props.onChangeValue(e.checked, 'customsClearance')}/>
                            <label htmlFor="customsClearance">Customs clearance</label>
                        </div>
                        <div className={'p-field additionalInfoCheckbox'}>
                            <Checkbox inputId="verifiedGrossMass" checked={props.booking.verifiedGrossMass}
                                      disabled={props.checkDisabledField('verifiedGrossMass')}
                                      onChange={e => props.onChangeValue(e.checked, 'verifiedGrossMass')}/>
                            <label htmlFor="verifiedGrossMass">Verified Gross Mass</label>
                        </div>
                        <div className={'p-field additionalInfoCheckbox'}>
                            <Checkbox inputId="proofOfDelivery" checked={props.booking.proofOfDelivery}
                                      disabled={props.checkDisabledField('proofOfDelivery')}
                                      onChange={e => props.onChangeValue(e.checked, 'proofOfDelivery')}/>
                            <label htmlFor="proofOfDelivery">Proof Of Delivery</label>
                        </div>
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <div className={'p-field additionalInfoCheckbox'}>
                            <Checkbox inputId="shippingInstruction" checked={props.booking.shippingInstruction}
                                      disabled={props.checkDisabledField('shippingInstruction')}
                                      onChange={e => props.onChangeValue(e.checked, 'shippingInstruction')}/>
                            <label htmlFor="shippingInstruction">Shipping instruction</label>
                        </div>
                        <div className={'p-field additionalInfoCheckbox'}>
                            <Checkbox inputId="kipis" checked={props.booking.kipis}
                                      disabled={props.checkDisabledField('kipis')}
                                      onChange={e => props.onChangeValue(e.checked, 'kipis')}/>
                            <label htmlFor="kipis">KIPIS</label>
                        </div>
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <div className={'p-field additionalInfoCheckbox'}>
                            <Checkbox inputId="insurance" checked={props.booking.insurance}
                                      disabled={props.checkDisabledField('insurance')}
                                      onChange={e => props.onChangeValue(e.checked, 'insurance')}/>
                            <label htmlFor="insurance">Insurance</label>
                        </div>
                    </div>
                </div>
            </AccordionTab>
            <AccordionTab header={<b>Loads</b>} disabled={props.activatedEdit}
                          headerStyle={{borderBottom: '1px solid rgba(0, 0, 0, 0.6)'}}>
                <div className="p-fluid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="placeOfLoading">PLL</label>
                        <AutoComplete id={'placeOfLoading'} value={props.booking.placeOfLoading}
                                      suggestions={filteredPlaces}
                                      disabled={props.checkDisabledField('placeOfLoading') || props.booking.mhPc === true}
                                      completeMethod={searchPlace} minLength={3} showEmptyMessage
                                      tooltip={'Min 3 characters'}
                                      onChange={(e) => props.onChangeValue(e, 'placeOfLoading')}
                                      emptyMessage={emptyMessage('place', 'placeOfLoading')} field="name"
                                      placeholder="-- Select --"
                                      className={classNames({'p-invalid': props.validMessage('placeOfLoading')})}/>
                        {props.validMessage('placeOfLoading') && <small style={{display: 'block'}} className="p-invalid">Required</small>}
                        <label htmlFor="mhPc" style={{marginTop: '5px'}}>MH p/c</label>
                        <Checkbox id={'mhPc'} checked={props.booking.mhPc}
                                  disabled={props.checkDisabledField('mhPc')}
                                  style={{position: 'relative', left: '5px',bottom: '5px'}}
                                  onChange={(e) => props.onChangeValue(e, 'mhPc')}/>
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="portOfLoading">POL</label>
                        <AutoComplete id={'portOfLoading'} value={props.booking.portOfLoading}
                                      suggestions={filteredPorts} disabled={props.checkDisabledField('portOfLoading')}
                                      completeMethod={searchPort} minLength={3} showEmptyMessage
                                      tooltip={'Min 3 characters'}
                                      onChange={(e) => props.onChangeValue(e, 'portOfLoading')}
                                      itemTemplate={portItemTemplate} field={portItemTemplate}
                                      placeholder="-- Select --"
                                      className={classNames({'p-invalid': props.validMessage('portOfLoading')})}/>
                        {props.validMessage('portOfLoading') && <small style={{display: 'block'}} className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="portOfDischarge">POD</label>
                        <AutoComplete id={'portOfDischarge'} value={props.booking.portOfDischarge}
                                      suggestions={filteredPorts} disabled={props.checkDisabledField('portOfDischarge')}
                                      completeMethod={searchPort} minLength={3} showEmptyMessage
                                      tooltip={'Min 3 characters'}
                                      onChange={(e) => props.onChangeValue(e, 'portOfDischarge')}
                                      itemTemplate={portItemTemplate} field={portItemTemplate}
                                      placeholder="-- Select --"
                                      className={classNames({'p-invalid': props.validMessage('portOfDischarge')})}/>
                        {props.validMessage('portOfDischarge') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="placeOfDischarge">PLD</label>
                        <AutoComplete id={'placeOfDischarge'} value={props.booking.placeOfDischarge}
                                      suggestions={filteredPlaces} disabled={props.checkDisabledField('placeOfDischarge') || props.booking.mhOc === true}
                                      completeMethod={searchPlace} minLength={3} showEmptyMessage
                                      tooltip={'Min 3 characters'}
                                      onChange={(e) => props.onChangeValue(e, 'placeOfDischarge')}
                                      emptyMessage={emptyMessage('place', 'placeOfDischarge')} field="name"
                                      placeholder="-- Select --"
                                      className={classNames({'p-invalid': props.validMessage('placeOfDischarge')})}/>
                        {props.validMessage('placeOfDischarge') && <small style={{display: 'block'}} className="p-invalid">Required</small>}
                        <label htmlFor="mhOc" style={{marginTop: '5px'}}>MH o/c</label>
                        <Checkbox id={'mhOc'} style={{position: 'relative', left: '5px',bottom: '5px'}}
                                  checked={props.booking.mhOc} disabled={props.checkDisabledField('mhOc')}
                                  onChange={(e) => props.onChangeValue(e, 'mhOc')}/>
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cutOffDate">CUT OFF</label>
                        <Calendar id="cutOffDate" showIcon
                                  viewDate={todayCalendar(props.booking.cutOffDate)} ref={calendarRef}
                                  onViewDateChange={(e) => onViewChange(e, calendarRef)} numberOfMonths={3}
                                  value={!isNullOrUndef(props.booking.cutOffDate) ? moment(props.booking.cutOffDate).toDate() : null}
                                  disabled={props.checkDisabledField('cutOffDate')}
                                  dateFormat={'yy-mm-dd'} placeholder="-- Select --" locale={'lt'}
                                  onChange={(e) => props.onChangeValue(e, 'cutOffDate')}
                                  className={classNames({'p-invalid': props.validMessage('cutOffDate')})}/>
                        {props.validMessage('cutOffDate') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="portOfLoadingDeparture">POL - ETD</label>
                        <Calendar id="portOfLoadingDeparture" showIcon disabled={props.checkDisabledField('portOfLoadingDeparture')}
                                  viewDate={todayCalendar(props.booking.portOfLoadingDeparture)} ref={calendarRef}
                                  onViewDateChange={(e) => onViewChange(e, calendarRef)} numberOfMonths={3}
                                  value={!isNullOrUndef(props.booking.portOfLoadingDeparture) ? moment(props.booking.portOfLoadingDeparture).toDate() : null}
                                  dateFormat={'yy-mm-dd'} placeholder="-- Select --" locale={'lt'}
                                  onChange={(e) => props.onChangeValue(e, 'portOfLoadingDeparture')}
                                  className={classNames({'p-invalid': props.validMessage('portOfLoadingDeparture')})}/>
                        {props.validMessage('portOfLoadingDeparture') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="portOfDischargeArrival">POD - ETA</label>
                        <Calendar id="portOfDischargeArrival" showIcon disabled={props.checkDisabledField('portOfDischargeArrival')}
                                  viewDate={todayCalendar(props.booking.portOfDischargeArrival)} ref={calendarRef}
                                  onViewDateChange={(e) => onViewChange(e, calendarRef)} numberOfMonths={3}
                                  value={!isNullOrUndef(props.booking.portOfDischargeArrival) ? moment(props.booking.portOfDischargeArrival).toDate() : null}
                                  dateFormat={'yy-mm-dd'} placeholder="-- Select --" locale={'lt'}
                                  onChange={(e) => props.onChangeValue(e, 'portOfDischargeArrival')}
                                  className={classNames({'p-invalid': props.validMessage('portOfDischargeArrival')})}/>
                        {props.validMessage('portOfDischargeArrival') && <small className="p-invalid">Required</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="portOfDischargeArrivalNotice">
                            POD - ETA UPDATE
                            <i className="pi pi-bars ml-2 p-overlay-badge"
                               onClick={() => setPodUpdateRegisterDialog(true)}
                               style={{cursor: 'pointer'}}>
                                {!isNullOrUndef(props.podUpdateRegister) && props.podUpdateRegister.length > 0 ?
                                    <Badge severity={'info'} id={'podUpdateRegister'}
                                           value={props.podUpdateRegister.length}/> : ''}
                            </i>
                            <Dialog visible={podUpdateRegisterDialog} onHide={() => setPodUpdateRegisterDialog(false)}
                                    header={'POD Update Register'} breakpoints={{'960px': '75vw', '640px': '100vw'}}
                                    style={{width: '50vw'}}>
                                <DataTable value={props.podUpdateRegister} emptyMessage={'No changes'}
                                           size="small" responsiveLayout="scroll">
                                    <Column field={'user'} header={'User'}
                                            body={(r) => r.user.firstName + ' ' + r.user.lastName}/>
                                    <Column field={'oldPortOfDischargeArrivalNotice'} header={'Old Date'}
                                            body={(r) => moment(r.oldPortOfDischargeArrivalNotice).format('YYYY-MM-DD')}/>
                                    <Column field={'newPortOfDischargeArrivalNotice'} header={'New Date'}
                                            body={(r) => moment(r.newPortOfDischargeArrivalNotice).format('YYYY-MM-DD')}/>
                                    <Column field={'changeDateTime'} header={'Changed Date'}
                                            body={(r) => moment(r.changeDateTime).format('YYYY-MM-DD HH:MM')}/>
                                </DataTable>
                            </Dialog>
                        </label>
                        <Calendar id="portOfDischargeArrivalNotice" showIcon
                                  disabled={props.checkDisabledField('portOfDischargeArrivalNotice')}
                                  viewDate={todayCalendar(props.booking.portOfDischargeArrivalNotice)} ref={calendarRef}
                                  onViewDateChange={(e) => onViewChange(e, calendarRef)} numberOfMonths={3}
                                  value={!isNullOrUndef(props.booking.portOfDischargeArrivalNotice) ? moment(props.booking.portOfDischargeArrivalNotice).toDate() : null}
                                  dateFormat={'yy-mm-dd'} placeholder="-- Select --" locale={'lt'}
                                  onChange={(e) => props.onChangeValue(e, 'portOfDischargeArrivalNotice')}
                                  className={classNames({'p-invalid': props.validMessage('portOfDischargeArrivalNotice')})}/>
                        {props.validMessage('portOfDischargeArrivalNotice') && <small className="p-invalid">Required</small>}
                    </div>
                </div>
            </AccordionTab>
            <AccordionTab header={<b>Containers {containersCount()} And Cargos</b>}
                          headerStyle={{borderBottom: '1px solid rgba(0, 0, 0, 0.6)'}}>
                <div className="p-fluid p-grid">
                    <div className="p-field p-col-12 p-md-9">
                        <div className="p-fluid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <CargosModule booking={props.booking} onChangeValue={props.onChangeValue}
                                              validMessage={props.validMessage} validThisForm={props.validThisForm}
                                              checkDisabledField={props.checkDisabledField}
                                              toast={props.toast} isClosed={props.isClosed}
                                              warnAboutActiveEdit={props.warnAboutActiveEdit()}
                                              activatedEdit={props.activatedEdit}
                                              setActivatedEdit={props.setActivatedEdit}
                                              containerTypes={props.containerTypes}
                                              checkUserRole={props.checkUserRole}/>
                            </div>
                        </div>
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <CargoItemsModule booking={props.booking} onChangeValue={props.onChangeValue}
                                          validMessage={props.validMessage}
                                          checkDisabledField={props.checkDisabledField}
                                          toast={props.toast} isClosed={props.isClosed}
                                          warnAboutActiveEdit={props.warnAboutActiveEdit()}
                                          activatedEdit={props.activatedEdit}
                                          setActivatedEdit={props.setActivatedEdit}/>
                    </div>
                </div>
            </AccordionTab>
        </Accordion>
        <div className="p-field p-col-12 p-md-12">
            <br/>
            <label htmlFor="remarks"><b>Remarks</b></label>
            <InputTextarea id="remarks" value={!isNullOrUndef(props.booking.remarks) ? props.booking.remarks : ''}
             onChange={(e) => props.onChangeValue(e, 'remarks')} rows={5} cols={30} autoResize  />
        </div>
    </div>

}