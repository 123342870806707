import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {StatusService} from '../service/StatusService';
import {useMountEffect} from "primereact";
import {isNullOrUndef} from "chart.js/helpers";

export const Status = () => {

    let emptyStatus = {
        id: null,
        name: ''
    };

    const [statuses, setStatuses] = useState(null);
    const [statusDialog, setStatusDialog] = useState(false);
    const [deleteStatusDialog, setDeleteStatusDialog] = useState(false);
    const [status, setStatus] = useState(emptyStatus);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const statusService = new StatusService();


    useMountEffect(() => {
        statusService.getStatuses()
            .then(data => {
                setStatuses(data);
                setLoading(false);
            });
    });

    const openNew = () => {
        setStatus(emptyStatus);
        setSubmitted(false);
        setStatusDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setStatusDialog(false);
    }

    const hideDeleteStatusDialog = () => {
        setDeleteStatusDialog(false);
    }

    const saveStatus = () => {
        setSubmitted(true);

        if (status.name.trim()) {
            let _statuses = [...statuses];
            let _status = {...status};

            statusService.saveStatus(_status)
                .then(data => {
                    if (status.id) {
                        const index = findIndexById(status.id);
                        _statuses[index] = data;
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Status Updated',
                            life: 3000
                        });
                    } else {
                        _statuses.push(data);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Status Created',
                            life: 3000
                        });
                    }
                    setStatuses(_statuses);
                    setStatusDialog(false);
                    setStatus(emptyStatus);
                })
                .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
                });
        }
    }

    const editStatus = (status) => {
        setStatus({ ...status });
        setStatusDialog(true);
    }

    const confirmDeleteStatus = (status) => {
        setStatus(status);
        setDeleteStatusDialog(true);
    }

    const deleteStatus = () => {
        statusService.deleteStatus(status.id)
            .then(data => {
                if(!isNullOrUndef(data) && !data.success) {
                    setDeleteStatusDialog(false);
                    setStatus(emptyStatus);
                    toast.current.show({severity: 'error', summary: 'Error', detail: data.errorMessage, life: 3000});
                } else {
                    let _statuses = statuses.filter(val => val.id !== status.id);
                    setStatuses(_statuses);
                    setDeleteStatusDialog(false);
                    setStatus(emptyStatus);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Status Deleted',
                        life: 3000
                    });
                }
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < statuses.length; i++) {
            if (statuses[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _status = { ...status };
        _status[`${name}`] = val;

        setStatus(_status);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                {rowData.name}
            </>
        );
    }

    const rankBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Rank</span>
                {rowData.rank}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'} onClick={() => editStatus(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text p-button-sm"
                        title={'Delete'} onClick={() => confirmDeleteStatus(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Status</h5>
        </div>
    );

    const statusDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveStatus} />
        </>
    );
    const deleteStatusDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteStatusDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteStatus} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}/>

                    <DataTable ref={dt} value={statuses} selection={selectedStatus} onSelectionChange={(e) => setSelectedStatus(e.value)}
                               dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" loading={loading}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} status"
                               emptyMessage="No Status found." header={header}>
                        <Column field="name" header="name" sortable body={titleBodyTemplate}/>
                        <Column field="rank" header="rank" sortable body={rankBodyTemplate}/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>

                    <Dialog visible={statusDialog} style={{ width: '450px' }} header="Status Details" modal className="p-fluid" footer={statusDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={status.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !status.name })} />
                            {submitted && !status.title && <small className="p-invalid">Title is required.</small>}
                            <label htmlFor="rank">Rank</label>
                            <InputText id="rank" value={status.rank} onChange={(e) => onInputChange(e, 'rank')} required className={classNames({ 'p-invalid': submitted && !status.name })} />
                            {submitted && !status.rank && <small className="p-invalid">Rank is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteStatusDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteStatusDialogFooter} onHide={hideDeleteStatusDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {status && <span>Are you sure you want to delete <b>{status.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
