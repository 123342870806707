import React, {useState, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Toolbar} from 'primereact/toolbar';
import {useMountEffect} from "primereact";
import {isNullOrUndef} from "chart.js/helpers";
import {WagesRatesService} from "../service/WagesRatesService";
import moment from "moment/moment";
import {InputNumber} from "primereact/inputnumber";

export const WagesRates = () => {

    const [wagesRates, setWagesRates] = useState([]);
    const wagesRatesService = new WagesRatesService();
    const toast = useRef(null);

    useMountEffect(() => {
        wagesRatesService.getWagesRates()
            .then(data => {
                setWagesRates(data);
            });
    });

    const saveWagesRates = (newData) => {
        wagesRatesService.saveWagesRates(newData)
            .then(data => {
                if (data) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Wages Rates Updated!',
                        life: 3000
                    });
                }
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Wages Rates</h5>
        </div>
    );

    const dateBody = (data) => {
        if (isNullOrUndef(data.lastUpdatedAt))
            return null

        return <span>{moment(data.lastUpdatedAt).format('YYYY-MM-DD HH:MM').toString()}</span>
    }


    const onRowEditComplete = (e) => {
        let _wagesRates = [...wagesRates];
        let {newData, index} = e;
        _wagesRates[index] = newData;
        saveWagesRates(newData)
        setWagesRates(_wagesRates);
    };

    const rateEditor = (options) => {
        return <InputNumber value={options.value} min={0} max={100} step={1} useGrouping={false} showButtons
                            onValueChange={(e) => options.editorCallback(e.value)}/>;
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast}/>
                    <Toolbar className="p-mb-4 p-toolbar" left={header}/>
                    <DataTable value={wagesRates}
                               editMode="row"
                               dataKey="id"
                               onRowEditComplete={onRowEditComplete}>
                        <Column field="id" header="ID"/>
                        <Column field="title" header="Title"/>
                        <Column field="wageRate" header="Wage rate" editor={(options) => rateEditor(options)}/>
                        <Column field="lastUpdatedAt" header="Last updated at" body={dateBody}/>
                        <Column header="Action" rowEditor headerStyle={{width: '10%', minWidth: '8rem'}}
                                bodyStyle={{textAlign: 'center'}}/>
                    </DataTable>

                </div>
            </div>
        </div>
    )
        ;
}
