import React, {useState, useRef, useEffect} from 'react';
import {Toast} from "primereact/toast";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {isNullOrUndef} from "chart.js/helpers";
import moment from "moment";
import {useMountEffect} from "primereact/hooks";
import {ToggleButton} from "primereact/togglebutton";
import {MultiSelect} from "primereact/multiselect";
import {LazySortMultiFields} from "../../components/LazySortMultiFields";
import {CostsService} from "../../service/CostsService";
import {CostsFilters} from "../bookings/modules/CostsFilters";
import {StatusService} from "../../service/StatusService";
import {useHistory} from "react-router-dom";

export const CostsReport = (props) => {

    const [costsList, setCostsList] = useState([])
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loadFirstTime, setLoadFirstTime] = useState(true);
    const [filter, setFilter] = useState(false);
    const [searchBooking, setSearchBooking] = useState(0);
    const [clearFilter, setClearFilter] = useState(0);
    const [filterColumns, setFilterColumns] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [filters, setFilters] = useState('');
    const [selectedColumns, setSelectedColumns] = useState([]);
    const costsService = new CostsService();
    const statusService = new StatusService();
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        multiSortMeta: [],
        filters: null
    });
    let loadLazyTimeout = null;

    const columns = [
        {key: 'refId', header: 'Ref.ID', field: 'booking.id', width: '60px', order: 1, disabling: false, filter: true},
        {key: 'containerNumber', header: 'Containers', field: 'booking.cargos.containerNumber', width: '100px', order: 2, disabling: false, filter: true},
        {key: 'bookingDate', header: 'Booking ID', field: 'booking.bookingDate', width: '120px', order: 3, disabling: false, filter: true},
        {key: 'client', header: 'Vendor', field: 'client.title', width: '300px', order: 4, disabling: false, filter: true},
        {key: 'statusId', header: 'Status', field: 'booking.status', width: '160px', order: 5, disabling: false, filter: true},
        {key: 'initialPlanPrice', header: 'Init plan price', field: 'initialPlanPrice', width: '160px', order: 6, disabling: false, filter: true},
        {key: 'planPrice', header: 'Plan price', field: 'planPrice', width: '160px', order: 7, disabling: false, filter: true},
        {key: 'costsManager', header: 'Vendor manager', field: 'client.costsManager', width: '160px', order: 8, disabling: false, filter: true},
        {key: 'bookingManager', header: 'Booking Manager', field: 'booking.manager', width: '160px', order: 9, disabling: false, filter: true},
        {key: 'operator', header: 'Operator', field: 'booking.operator', width: '160px', order: 10, disabling: false, filter: true},
    ];

    useMountEffect(() => {
        const filterToggleStorage = JSON.parse(window.localStorage.getItem('costs-report-filter-toggle'));
        if (!isNullOrUndef(filterToggleStorage)) {
            setFilter(filterToggleStorage);
        }
        statusService.getStatuses().then(data => {
            setStatusOptions(data)
        })
        setLoading(true);
        getCosts()
        setFilterColumns(columns.filter(c => c.filter))
    })

    useEffect(() => {
        if (!loadFirstTime) {
            setLoading(true);

            if (loadLazyTimeout) {
                clearTimeout(loadLazyTimeout);
            }

            loadLazyTimeout = setTimeout(() => {
                getCosts()
            }, 350);
        }
    }, [lazyParams, filters]);

    const getCosts = (exportToExcel) => {
        let filterParams = ''
        const columnsStorage = JSON.parse(window.localStorage.getItem('costs-report-datatable-columns'));
        if (columnsStorage !== null) {
            setSelectedColumns(columnsStorage.sort((a, b) => a.order - b.order))
        } else {
            setSelectedColumns(columns.sort((a, b) => a.order - b.order))
            window.localStorage.setItem('costs-report-datatable-columns', JSON.stringify(columns))
        }
        const filterParamsStorage = JSON.parse(window.localStorage.getItem('costs-report-filter-data'));
        if (!isNullOrUndef(filters) && filters !== '') {
            filterParams = `&filter=${filters}`
            window.localStorage.setItem('costs-report-filter-data', JSON.stringify(filters))
        } else if (!isNullOrUndef(filterParamsStorage)) {
            filterParams = `&filter=${filterParamsStorage}`
        }
        let sortFields = ''
        const sortParamsStorage = JSON.parse(window.localStorage.getItem('costs-report-sort-data'));
        if (!isNullOrUndef(lazyParams.multiSortMeta) && lazyParams.multiSortMeta.length > 0) {
            lazyParams.multiSortMeta.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            window.localStorage.setItem('costs-report-sort-data', JSON.stringify(lazyParams.multiSortMeta))
        } else if(!isNullOrUndef(sortParamsStorage)) {
             sortParamsStorage.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            let _lazyParams = {...lazyParams}
            _lazyParams.multiSortMeta = sortParamsStorage
            setLazyParams(_lazyParams)
        }
        const dtStateLocal = JSON.parse(window.localStorage.getItem('costs-report-dt-state-local'));
        if (!isNullOrUndef(dtStateLocal)) {
            let _lazyParams = lazyParams;
            _lazyParams.rows = dtStateLocal.rows
            _lazyParams.first = dtStateLocal.first
            _lazyParams.page = dtStateLocal.first / dtStateLocal.rows
        }
        if (exportToExcel) {
            exportPlanRealToExcel(filterParams, sortFields)
        } else {
            getCostsWithParams(filterParams, sortFields)
        }
    }

    const exportPlanRealToExcel = (filterParams, sortFields) => {
        let columns = selectedColumns.map(c => c.field).join(",");
        setLoading(true)
        costsService.exportToExcel(sortFields, filterParams, columns)
            .then(r => {
                if (r.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([r.data],
                        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                    const link = document.createElement('a')
                    link.href = url;
                    link.setAttribute('download', 'Kaupimai_' + moment(new Date()).format('YYYY-MM-DD_hh:mm:ss') + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                setLoading(false)
            })
    }

    const getCostsWithParams = (filterParams, sortFields) => {
        costsService.getCosts(lazyParams.page, lazyParams.rows, sortFields, filterParams)
            .then(data => {
                setTotalRecords(data.data.totalElements);
                setCostsList(data.data.content);
                setLoading(false);
                setLoadFirstTime(false)
            });
    }

    const bookingStatusTemplate = (statusId) => {
        return statusOptions.find(s  => s.id === statusId).name
    }

    const bookingDateTemplate = (rowData) => {
        if(isNullOrUndef(rowData.booking.bookingDate))
            return

        return <div
            key={'bookingDate' + rowData.id}>{moment(rowData.booking.bookingDate).format('YYYY-MM-DD')}</div>
    }

    const onFilterToggle = (e) => {
        setFilter(e.value)
        window.localStorage.setItem('costs-report-filter-toggle', JSON.stringify(e.value))
    }

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.key === col.key));
        setSelectedColumns(orderedSelectedColumns.sort((a, b) => a.order - b.order));
        window.localStorage.setItem('costs-report-datatable-columns', JSON.stringify(selectedColumns))
    }

    const header = <>
        <div className="table-header">
            <h5 className="p-mb-2">Kaupimai</h5>
        </div>
        <div className="flex justify-content-between flex-wrap card-container">
            <div className="flex align-items-center justify-content-center">
                <MultiSelect value={selectedColumns} options={columns} maxSelectedLabels={0}
                             optionDisabled={option => option.disabling}
                             placeholder="Select Item" id={'costs-selectColumns'} filter
                             optionLabel={'header'} onChange={onColumnToggle} style={{width: '20em'}}/>
                <ToggleButton checked={filter} onChange={onFilterToggle} className={'p-ml-3'}
                              onLabel="" offLabel="" onIcon="pi pi-filter-slash"
                              offIcon="pi pi-filter" aria-label="Filters"/>
                <span className={'p-buttonset p-ml-3'}>
                            <Button icon="pi pi-search" iconPos="right"
                                    onClick={() => setSearchBooking(searchBooking + 1)}/>
                            <Button icon="pi pi-times" iconPos="right" onClick={() => setClearFilter(clearFilter + 1)}/>
                        </span>
            </div>
            <div className="flex align-items-center justify-content-center">
                <Button icon="pi pi-file-excel" iconPos="right" label={'Export'}
                        onClick={() => getCosts(true)}/>
            </div>
        </div>
        <div>
            <CostsFilters loading={loading} selectedColumns={filterColumns} filter={filter} clientGroupId={props.getClientGroupId('vendor')}
                            searchBooking={searchBooking} clearFilter={clearFilter} statusOptions={statusOptions}
                            getCosts={getCosts} filters={filters} setFilters={setFilters}/>
        </div>
    </>;

    const priceColumnTemplate = (key, rowData, field) => {
        if(key === 'planPrice'){
            let currency = !isNullOrUndef(rowData.planCurrency) ? rowData.planCurrency.code : ''
            return rowData.planPrice + ' ' + currency
        } else if(key === 'initialPlanPrice'){
            let currency = !isNullOrUndef(rowData.initialPlanCurrency) ? rowData.initialPlanCurrency.code : ''
            return rowData.initialPlanPrice + ' ' + currency
        } else {
            return ''
        }
    }

    const managerTemplate = (manager) => {
        if(!isNullOrUndef(manager)) {
            return manager.firstName + ' ' + manager.lastName
        } else {
            return ''
        }
    }

    const goToBooking = (bookingId) => {
        history.push(`/bookings/view/${bookingId}`);
    }

    const refIdTemplate = (bookingId) => {
        return <Button label={bookingId} key={'booking' + bookingId} className="p-button-link p-0 p-m-0"
                       style={{display: 'flex'}}
                       onClick={() => goToBooking(bookingId)}/>
    }

    const cargosFieldBody = (rowData) => {
        if (rowData.booking.cargos.length === 0 || rowData.booking.cargos.length === 0)
            return

        let contNumber = rowData.booking.cargos.map(c => {
            return <div key={'contNumber-' + c.id}
                        id={'contNumber-' + c.id}>{c.containerNumber}</div>
        })

        return <div style={{display: "grid", textAlign: "center"}}>
            {contNumber}
        </div>
    }

    const getBodyTemplate = (key, rowData, field) => {
        switch (key) {
            case 'refId':
                return !isNullOrUndef(rowData.booking) ? refIdTemplate(rowData.booking.id) : ''
            case 'bookingDate':
                return !isNullOrUndef(rowData.booking) ? bookingDateTemplate(rowData) : ''
            case 'client':
                return !isNullOrUndef(rowData.client) ? rowData.client.title : ''
            case 'statusId':
                return !isNullOrUndef(rowData.booking) ? bookingStatusTemplate(rowData.booking.statusId) : ''
            case 'costsManager':
                return !isNullOrUndef(rowData.client.costsManager) ? managerTemplate(rowData.client.costsManager) : ''
            case 'operator':
                return !isNullOrUndef(rowData.booking.operator) ? managerTemplate(rowData.booking.operator) : ''
            case 'bookingManager':
                return !isNullOrUndef(rowData.booking.manager) ? managerTemplate(rowData.booking.manager) : ''
            case 'containerNumber':
                return !isNullOrUndef(rowData.booking.cargos) ? cargosFieldBody(rowData) : ''
            case 'planPrice':
            case 'initialPlanPrice':
                return !isNullOrUndef(rowData[field]) ? priceColumnTemplate(key, rowData, field) : 0
            default:
                return !isNullOrUndef(rowData[field]) ? rowData[field] : ''
        }
    }

    const columnComponents = selectedColumns.map(col => {
        return <Column style={{minWidth: col.width, width: col.width}} key={col.key} field={col.field} sortable={false}
                       header={<LazySortMultiFields header={col.header} fieldKey={col.key}
                                                    field={col.field} lazyParams={lazyParams}
                                                    crud={'costs-report'}
                                                    setLazyParams={setLazyParams}/>}
                       body={rowData => getBodyTemplate(col.key, rowData, col.field)}/>;
    });

    return (
        <div className="p-grid" style={{margin: '0 -20px'}}>
            <div className="p-col-12">
                <Toast ref={toast}/>
                <div style={{height: 'calc(100vh - 115px)', fontSize: '15px'}}>
                <DataTable ref={dt} value={costsList}
                           // footerColumnGroup={footerGroup}
                           size="small"
                           dataKey="id" paginator rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
                           onPage={(e) => setLazyParams(e)} totalRecords={totalRecords} lazy
                           stateStorage="local" scrollable
                           responsiveLayout="scroll"
                           scrollHeight="flex" id={'costs-list'}
                           stateKey="costs-report-dt-state-local"
                           className="datatable-responsive" loading={loading} first={lazyParams.first} rowHover
                           rows={lazyParams.rows}
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} bookings"
                           emptyMessage="No bookings found." header={header}>
                    {columnComponents}
                </DataTable>
                </div>
            </div>
        </div>
    );

}